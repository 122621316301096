window.attachRemoveCartProductEvent = function ()
{
    $('.remove-cart-product').on('click', function () {

        removeCartProduct(
            $(this).attr('data-remove-cart-product-url'),
            $(this).attr('data-cart-product-key'),
            $(this).attr('data-product-title'),
            $('#is-cart-page').val()
        )
    })
}

window.removeCartProduct = function (url, cartProductSlug, productName, isCartPage)
{
    $.ajax({
        type: "post",
        url: url,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            cart_product_slug: cartProductSlug,
            is_cart_page: isCartPage
        },
        success: function (response) {
            const $headerCartContainer = $('#header-cart-container')
            const $mobileFooterContainer = $('#sticky-bar-cart')
            const $cartCountEl = $('.cart-count')

            if (response.status === 'success') {

                Wolmart.Minipopup.open({
                    productClass: 'product-cart',
                    name: productName,
                    imageSrc: response.avatar_image_path,
                    message: '<p>' + response.message + '</p>'
                });

                $headerCartContainer.html(response.html)
                $mobileFooterContainer.html(response.footer_html)
                $cartCountEl.text(response.product_count)

                if (response.cart_html) {
                    $('#cart-listing-container').html(response.cart_html)
                }

                attachRemoveCartProductEvent()

            } else {

                if(response.cart_url) {
                    window.location.replace(response.cart_url)
                }

                Wolmart.Minipopup.open({
                    productClass: 'product-cart',
                    name: productName,
                    imageSrc: $('#product-properties').attr('data-error-image-url'),
                    message: '<p>' + response.message + '</p>'
                });

                $headerCartContainer.html(response.html)
                $mobileFooterContainer.html(response.footer_html)
                $cartCountEl.text(0)

                if (response.cart_html) {
                    $('#cart-listing-container').html(response.cart_html)
                }
            }
        }
    })
}
